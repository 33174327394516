// @External Dependencies
import { combineReducers } from 'redux'

// @Dependencies
import { loggedUser } from './Users'
import { courses } from './Courses'
import { ui } from './UI'
import { programs } from './Programs'
import { exercises } from './Exercises'
import { profile } from './Profile'
import { comments } from './Comments'
import { requestError } from './RequestError'
import { presencialResume } from './PresencialResume'
import { pdf } from './PDF'
import { units } from './Units'
import { cev } from './CEV'
import { notification } from './Notification'
import { calendar } from './Calendar'

// @Export Reducers
export default combineReducers({
  loggedUser,
  courses,
  ui,
  programs,
  exercises,
  profile,
  comments,
  requestError,
  presencialResume,
  pdf,
  units,
  cev,
  notification,
  calendar,
})
