export const CalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="none">
      <circle cx={22.5} cy={22.5} r={22.5} fill="#E0EEFA" />
      <path fill="#FF554D" d="M29 30a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z" />
      <path fill="#1EBDAF" d="M19.5 30a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z" />
      <path fill="#F5A623" d="M10 30a3 3 0 1 0 6 0 3 3 0 0 0-6 0Z" />
      <path
        fill="#08044F"
        fillRule="evenodd"
        d="M10 22a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM29 22a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM19.5 22a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
        clipRule="evenodd"
      />
      <path fill="#0189FF" d="M4 8a2 2 0 0 1 2-2h33a2 2 0 0 1 2 2v9H4V8Z" />
      <path
        fill="#08044F"
        fillRule="evenodd"
        d="M6 13v24h33V13H6Zm-1-2a1 1 0 0 0-1 1v25a2 2 0 0 0 2 2h33a2 2 0 0 0 2-2V12a1 1 0 0 0-1-1H5Z"
        clipRule="evenodd"
      />
      <path
        fill="#08044F"
        fillRule="evenodd"
        d="M11 15a1 1 0 0 1-1-1V9a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1ZM34 15a1 1 0 0 1-1-1V9a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1ZM22.5 15a1 1 0 0 1-1-1V9a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
