import { CampusHelpCenterPayload } from '@eclass/api'
import { UIReducerAction, UIReducer, Payloads } from './types'

import { InitialState } from './initialState'
import { dispatchBranding } from './dispatchBranding'

/**
 * Reducer de UI
 *
 * @param {UIReducer} state - Valores iniciales del Reducer.
 * @param {UIReducerAction} action - dispatch disparado.
 *
 * @returns {UIReducer} Reducer.
 */
export const ui = (state: UIReducer = InitialState, action: UIReducerAction): UIReducer => {
  /**
   * Evalúa cada dispatch y hace el return correspondiente.
   */
  switch (action.type) {
    case 'COURSE_WELCOME':
      return {
        ...state,
        ...dispatchCourseWelcome(action.payload),
      }
    case 'SET_MEDIAQUERY':
      return {
        ...state,
        ...dispatchIsMobile(action.payload),
      }
    case 'SET_HELP_CENTER':
      return {
        ...state,
        ...dispatchHelpCenter(action.payload),
      }
    case 'SET_BRANDING':
      return {
        ...state,
        ...dispatchBranding(action.payload),
      }
    case 'SET_I18N':
      return {
        ...state,
        ...dispatchLanguage(action.payload),
      }
    case 'SET_I18N_COURSE':
      return dispatchLanguageCourse(state, action.payload)
    case 'SET_KEEPTIMEZONE':
      return {
        ...state,
        ...dispatchKeepTimeZone(action.payload),
      }
    case 'UI_ADD_COURSE_COLORS':
      return {
        ...state,
        ...dispatchCourseColors(state, action.payload),
      }
    default:
      return state
  }
}

/**
 * Cambia el idioma de la plataforma.
 */
const dispatchLanguage = (payload: string): UIReducer => {
  return {
    i18n: payload,
  }
}

/**
 * Cambia el toggleWelcome true/false.
 */
const dispatchCourseWelcome = (payload: boolean): UIReducer => {
  return {
    toggleWelcome: payload,
  }
}

/**
 * Cambia el isMobile true/false.
 */
const dispatchIsMobile = (payload: boolean): UIReducer => {
  return {
    isMobile: payload,
  }
}

/**
 * Cambia el isMobile true/false.
 */
const dispatchHelpCenter = (payload: CampusHelpCenterPayload): UIReducer => {
  return {
    HELP_CENTER: payload,
  }
}

/**
 * Guarda el idioma del curso en el objeto `i18nCourses`
 */
const dispatchLanguageCourse = (state: UIReducer, payload: Payloads.CourseLanguage): UIReducer => {
  const tempLangs = state.i18nCourses instanceof Object ? state.i18nCourses! : {}

  // seteamos el curso nuevo o sobreescribimos
  tempLangs[payload.courseId] = payload.i18n

  return {
    ...state,
    i18nCourses: tempLangs,
  }
}

/**
 * Cambia si mantiene o no la zona horaria frue/false `keepTimeZone`
 */

const dispatchKeepTimeZone = (payload: boolean): UIReducer => {
  return {
    keepTimeZone: payload,
  }
}

const dispatchCourseColors = (state: UIReducer, payload: any): UIReducer => {
  const colors = [
    '#832DA4',
    '#00857A',
    '#C63361',
    '#5383EC',
    '#DF742C',
    '#A1635C',
    '#5C1179',
    '#AE1042',
    '#3C4DE4',
    '#C36220',
    '#82504A',
  ]

  const courseColors = { ...state.courseColors }

  // Encuentra el índice del último color usado
  let lastUsedIndex = Object.values(courseColors).reduce((maxIndex, color) => {
    const colorIndex = colors.indexOf(color)
    return colorIndex > maxIndex ? colorIndex : maxIndex
  }, -1)

  const uniqueCalendarIds: string[] = Array.from(
    new Set(payload.map((event) => String(event.course.id)))
  )

  uniqueCalendarIds.forEach((item) => {
    const existsInCourseColors = Object.prototype.hasOwnProperty.call(courseColors, item)
    if (!existsInCourseColors) {
      // Incrementar el índice y reiniciar si excede la longitud de `paleta`
      lastUsedIndex = (lastUsedIndex + 1) % colors.length

      // Agregar el nuevo elemento al objeto `hola`
      courseColors[item] = colors[lastUsedIndex]
    }
  })

  return {
    courseColors,
  }
}
