import { CalendarReducerAction, CalendarReducer } from './types'

import { InitialState } from './initialState'

export const calendar = (
  state: CalendarReducer = InitialState,
  action: CalendarReducerAction
): CalendarReducer => {
  switch (action.type) {
    case 'CALENDAR_ADD_COURSE_COLORS':
      return {
        ...state,
        ...dispatchCourseColors(state, action.payload),
      }
    default:
      return state
  }
}

export const colorsCalendar = [
  '#832DA4',
  '#00857A',
  '#C63361',
  '#5383EC',
  '#DF742C',
  '#A1635C',
  '#5C1179',
  '#AE1042',
  '#3C4DE4',
  '#C36220',
  '#82504A',
]

const dispatchCourseColors = (state: CalendarReducer, payload: any): CalendarReducer => {
  const courseColors = { ...state.courseColors }

  // TODO: si vienen colores desde CV agregarlos a la store

  // Encuentra el índice del último color usado
  let lastUsedIndex = Object.values(courseColors).reduce((maxIndex, color) => {
    const colorIndex = colorsCalendar.indexOf(color)
    return colorIndex > maxIndex ? colorIndex : maxIndex
  }, -1)

  const uniqueCalendarIds: string[] = Array.from(
    new Set(payload.map((event) => String(event.course.id)))
  )

  uniqueCalendarIds.forEach((item) => {
    const existsInCourseColors = Object.prototype.hasOwnProperty.call(courseColors, item)
    if (!existsInCourseColors) {
      // Incrementar el índice y reiniciar si excede la longitud de `paleta`
      lastUsedIndex = (lastUsedIndex + 1) % colorsCalendar.length

      // Agregar el nuevo elemento al objeto `hola`
      courseColors[item] = colorsCalendar[lastUsedIndex]
    }
  })

  return {
    courseColors,
  }
}
